@import 'bootstrap/scss/bootstrap';
@import "1-variables/grid.variables";
@import "1-variables/colours.variables";

.action-button-outline {
  min-height: 3rem;
  border-radius: 3px;
  border: 2px solid #0066cc;
  background-color: white;
  color: #0066cc;
  font-family: "Open Sans", "Helvetica Neue", "sans-serif";
  font-size: 1rem;

  &:hover {
    background-color: #0088ee;
  }
}

ul.dashed {
  font-size: 1rem;
  font-family: "Open Sans";
  margin: 0;
  font-weight: normal;
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  text-indent: -5px;
}
ul li {
  padding: 0;
}
.progress-bar-ul {
  margin: 0.5rem 0 1.5rem 1.9rem;
  @media (min-width: 351px) and (max-width: 820px) {
    margin: 0.5rem 0 1.5rem 1.5rem;
  }
}

.mt-5,
.my-5 {
  margin-top: 1.875rem !important;
}

.important-text {
  font-size: 1rem;
  font-weight: bold;
}

.mccssWarning {
  font-size: 1rem;
  color: #000000;
  border-radius: 4px;
  background-color: #e2f0f4;
  padding: 1rem;
}

.start-button {
  display: inline-block;
  color: white;
  background-color: #0066cc;
}

.form-group {
  margin-bottom: 2.5rem !important;
}

.ontario-form-group-with-margin-bottom {
  margin-top: 0 !important;
  margin-bottom: 2.5rem !important;
  @extend .ontario-form-group;

  &:last-of-type {
    margin-bottom: 2.5rem !important;
  }
}

img.cheque-image {
  width: 100%;
  max-width: 405px;
  height : auto;
  padding-top: 3%;

  @media screen and (max-width: 920px){
    max-width: 870px;
  }
}

.ontario-form-group-no-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  @extend .ontario-form-group;
}

.ontario-form-group {
  margin-top: 2.5rem;
  margin-bottom: 0;
  @extend .ontario-form-group;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.ontario-input--2-char-width {
  min-width: unset;
  width: 100%;
}

.ontario-input--3-char-width {
  min-width: unset;
  width: 100%;
}

.ontario-input--4-char-width {
  min-width: unset;
  width: 100%;
}

.ontario-input--5-char-width {
  min-width: unset;
  width: 100%;
}

.ontario-input--7-char-width {
  min-width: unset;
  width: 100%;
}

.ontario-input--10-char-width {
  min-width: unset;
  width: 100%;
}

.ontario-input--20-char-width {
  min-width: unset;
  width: 100%;
}

.ontario-label {
  max-width: none !important;
  @extend .ontario-label;
}

.ontario-label-normal {
  font-weight: normal !important;
  @extend .ontario-label;
}

.ontario-radios {
  @extend .ontario-radios;
  margin-bottom: 0 !important;
  max-width: unset;
}

.ontario-radios__item:last-of-type {
  margin-bottom: 0;
}

.ontario-radios__label:before {
  background-color: white;
}

.ontario-checkboxes {
  @extend .ontario-checkboxes;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  max-width: none !important;
}

.ontario-checkboxes__label:before {
  @extend .ontario-checkboxes__label, :before;
  top: 2px;
  left: 2px;
  background-color: white;
}

.ontario-accordion__content * {
  max-width: none;
}
.ontario-accordion__button {
  max-width: none;
}

.grey-background {
  background-color: $ontario-greyscale-5;
}

.divider {
  height: 4px;
  background-color: #ccc;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
  box-sizing: content-box;
  height: 0;
}

main p, main p:not(.ontario-lead-statement), main dd, main li {
  max-width: 100%;
}

a {
  color: #0066CC;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #00478F;
  }
  &:visited {
    color: #551A8B;
  }
}

@media (min-width: 320px) {
  .sada-container {
    max-width: 540px;
    margin: auto;
  }
}

@media (min-width: 576px) {
  .sada-container {
    max-width: 540px;
    margin: auto;
  }
}

.ontario-h1, h1 {
  @extend .ontario-h1;
  @media (max-width: 350px) {
    margin: 2rem 0 1rem 0;
    font-size: 1.35rem;
  }
  @media (min-width: 351px) and (max-width: 400px) {
    margin: 2rem 0 1rem 0;
    font-size: 1.75rem;
  }
}

@media (min-width: 768px) {
  .sada-container {
    max-width: 720px;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .sada-container {
    max-width: 720px;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .sada-container {
    max-width: 720px;
    margin: auto;
  }
}

input[type="text"]:disabled {
  background: #e9e9e9 !important;
}
input[type="radio"]:disabled {
  opacity: 1;
}

.mat-content {
  flex: unset !important;
}

.mandatoryFieldText, .timeoutText, #clear-cache-label {
  font-family: "Open Sans";
  font-size: 1.125rem;
  color: #000000;
}

.mat-autocomplete-panel {
  width: 100%;
  max-width: 48rem;
  background: #fff;
  color: #000000de;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

@media #{$small-only}  {
  .sada-dialog-class{
   max-height: calc(var(--vhWithoutBrowserElements,100vh) - 8px);
  }
}

.sada-dialog-class .mat-dialog-container{
  border-radius: 5px;
  margin: auto;
  max-width: 640px;
  background: #fff;
  color: #000000de;
  @media (max-width: 630px) {
    max-width: 460px;
  }
  @media (max-width: 350px) {
    max-width: 340px
  }
}

.dialog-title{
  margin-bottom: 0 !important;
}

.dialog-body-container{
  margin-right: 20px;
}

.dialog-body {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.close-button-div {
  margin-left: 20px;
}

.adjust-list-position-expandable-content{ // To apply lists style in hint-text content on the consent page
  ul {
    list-style-position: outside;
    padding-left: 0;
    margin-left: 1.25rem;
  }

  ul li {
    margin-left: 1rem;
  }

  ol {
    list-style-position: outside;
    padding-left: 0;
    margin-left: 1.25rem;
  }
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  @media #{$small-only} { // For mobile, display as stacked buttons (blocks) with no right margin
    display: block;
    div {
      margin-right: 0 !important;
    }
  }
}

.default-link {
  color: #0066CC !important;
  text-decoration: underline !important;
}

.default-link:visited {
  color: #551a8b !important;
}

.default-link:hover {
  color: #00478F !important;
  text-decoration: underline !important;
}

.default-link:active {
  color: #002142 !important;
}

input[type="checkbox"]:disabled + .ontario-checkboxes__label:before{
  background-color: #e9e9e9 !important;
}
